    #TutorAsideVideoCnt {
        position: absolute;
        right: 0;
        top: 90px;
        transition: 1s;

        height: calc(100vh - 220px);
        width: calc(100vw - 35px);
        z-index: 1000;
    }

    .TutorCollabFooter {
        height: 50px;
        width: 100%;
        position: fixed;
        right: 0px;
        bottom: 92px;
        padding: 0;
        background-color: #e6e6e6;
        border-radius: 5px;
    }

    .TutorAsideVideoCnt {
        height: 30%;
        width: 100%;
        background-color: #000;
        position: relative;
    }

    .TutorAsideVideoCnt video {
        height: 100%;
        width: 100%;

    }

    .TutorAsideVideoCnt ul {
        list-style-type: none;
        position: absolute;
        bottom: 5px;
        left: 0;
        padding: 0 0 0 5px;
        display: flex;
    }

    .TutorAsideVideoCnt ul li {
        margin: 0 2.5px 0 2.5px;
        padding: 8px;
    }


    .TutorAsideChatCnt {
        position: relative;
        width: 100%;
    }

    .TutorAsideChatBox {
        height: calc(100% - 60px);
        bottom: 55px;
        width: 100%;
        position: absolute;
        padding: 5px;
        overflow: auto;
        background-color: #fff;

    }



    .TutorAsideChatControl {
        height: 50px;
        bottom: 0;
        width: 100%;
        position: absolute;
        background-color: transparent;
        padding: 0 5px 0 5px;
        margin: 10px 0;
    }

    .TutorAsideChatControl button {
        height: 45px;
        font-weight: 'bold';
        border: none;
        cursor: pointer;
        outline: none;
    }


    .TutorMessageCnt {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: right;
        position: relative;
        margin: 0 0 8px 0;
    }

    .TutorMessageCntContent {
        max-width: 80%;
        text-align: left;
        padding: 10px;
        float: right;
        position: relative;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 1.15px;
        border-bottom-left-radius: 15px;
        background-color: #0062ff;
        color: #fff;
    }

    .TutorBoardAccessIndicator {
        height: 100%;
        width: 300px;
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: right;
    }

    .TutorBoardAccessIndicator button {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
        outline: none;
        justify-content: center;
    }



    /* countdown */

    .flip-countdown-title {
        display: none;
    }


    .card__top,
    .card__bottom {
        color: limegreen !important;
    }

    .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
    .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom,
    .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before,
    .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::after {
        color: rgb(41, 182, 41)
    }

    .excalidraw .App-menu_top {
        margin-top: -20px !important
    }