.card {
    max-width: 25rem;
    padding: 0;
    border: none;
    border-radius: 0.5rem;
}

.profile-motivation textarea,
.profile-headline textarea {
    text-align: justify;
    white-space: wrap;
}

a.active {
    border-bottom: 2px solid #55c57a;
}

.nav-link {
    color: rgb(110, 110, 110);
    font-weight: 500;
}

.nav-link:hover {
    color: #55c57a;
}

.nav-pills .nav-link.active {
    color: black;
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 600;
}

.tab-content {
    padding-bottom: 1.3rem;
}


.third {
    padding: 0 1.5rem 0 1.5rem;
}

label {
    font-weight: 500;
    color: rgb(104, 104, 104);
}

.btn-success {
    float: right;
}

.form-control:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 7px rgba(0, 0, 0, 0.2);
}


.search {
    padding: 0 1rem 0 1rem;
}

.ccontent li .wrapp {
    padding: 0.3rem 1rem 0.001rem 1rem;
}

.ccontent li .wrapp div {
    font-weight: 600;
}

.ccontent li .wrapp p {
    font-weight: 360;
}

.ccontent li:hover {
    background-color: rgb(117, 93, 255);
    color: white;
}


.addinfo {
    padding: 0 1rem;
}



.react-international-phone-input {
    width: 100%;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    border: 2px solid rgb(179, 179, 179);
    border-right: none;
    height: 50px !important
}

.react-international-phone-input-container .react-international-phone-input {
    border: 2px solid rgb(179, 179, 179) !important;
    border-left: none !important;
    height: 50px !important
}

.react-international-phone-input-container .react-international-phone-input--disabled {
    background: rgb(233, 236, 239) !important;
    opacity: 0.5 !important;
}

.react-international-phone-country-selector-button.react-international-phone-country-selector-button--disabled {
    background: rgb(233, 236, 239) !important;
    opacity: 0.5 !important;
}

ul.vertical-tabs .nav-link :hover {
    background: aliceblue !important;
}