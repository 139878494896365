html {
  padding: 0 !important;
}

body,
div {
  font-family: "Roboto", sans-serif;
}

p {
  margin: 0;
}

/* .roboto-thin,
p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
} */

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p {
  font-family: "Work Sans", sans-serif;
}

p {
  line-height: 1.3;
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 800;
}

h3 {
  font-weight: 700;
}

h4,
h5,
h6 {
  font-weight: 600;
}


span {
  color: rgb(129, 129, 129);
  /* font-weight: 400 !important; */
  font-family: "Work Sans", sans-serif !important;
}

:root {
  --backgroundColor: rgba(246, 241, 209);
  --colorShadeA: rgb(16, 16, 16);
  --colorShadeB: rgb(72, 72, 72);
  --colorShadeC: rgb(157, 157, 157);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes border-blinking {
  50% {
    border-color: limegreen;
  }
}

.blinking-frame-red {
  animation: blinking_red 1s infinite;
}

.blinking-border-bottom {
  border: none;
  border-bottom: 10px solid green;
  animation-name: border-blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}

.blinking-button {
  animation: blink 1s infinite;
}

.click-effect-elem {
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.click-effect-elem:active {
  background-color: #b7b7b7 !important;
}

/* -------------------------------------------------calender------------------------------------------- */
.rbc-calendar {
  min-height: 600px;
}

.rbc-label {
  font-weight: bold;
}

.rbc-label.rbc-time-header-gutter::before {
  content: "hh:mm a" !important;
}

.rbc-btn-group button:nth-child(1) {
  background-color: yellow !important;
  color: #000 !important;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.rbc-btn-group button:nth-child(2) {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.rbc-btn-group button:nth-child(3) {
  background-color: #007bff !important;
  color: #fff !important;
}

.rbc-btn-group button.rbc-active {
  padding: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5) !important;
}

.form-check-input border border-dark.gray-checkbox:checked {
  background-color: lightgray;
  border-color: black;
}

.rbc-toolbar-label {
  font-size: 20px;
  font-weight: 900;
}

.rbc-row.rbc-month-header {
  background-color: #007bff;
  color: white;
}

.rbc-row.rbc-time-header-cell .rbc-header {
  background-color: #4caf50 !important;
  color: white;
}

.rbc-header span {
  color: white;
}

.rbc-current-time-indicator:before {
  content: "Tutor UTC Time";
  font-size: 12px;
  bottom: 3px;
  position: absolute;
  font-weight: bold;
  color: black;
  width: 100%;
  margin-left: auto;
  text-align: center;
}

.student-calender .rbc-current-time-indicator:before {
  content: "Student UTC Time";
}

.rbc-current-time-indicator {
  height: 3px !important;
  background-color: rgb(230, 16, 16) !important;
}

.tutor-vacation-dayoff::after {
  content: "Day off";
  height: 100%;
  color: red;
  display: block;
  padding: 20px;
}

/* ----------------------------------------------------------react-datepicker--------------------------------------*/
.react-datepicker__time-container {
  width: 75px !important;
}

.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
}

.react-datepicker__input-container input {
  width: 100% !important;
  height: 50px;
}

/*-------------------------------------------------pdfviwer--------------------------------------------*/
.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas {
  /* width: 430px;
  height:500px; */
}

.react-pdf__Page__canvas {
  top: 0;
  /* width:430px !important;
  height:500px !important; */

}

.react-pdf__Page__textContent {
  display: none;
}

/*----TOAST------*/

.custom-toast-container {
  bottom: 100px;
}

.react-toastify-icon.toast-info svg {
  fill: white;
}

.Toastify__close-button>svg {
  width: 30px !important;
  height: 30px !important;
  color: rgb(0, 255, 51) !important;
  animation: blink 1s infinite;
}

.Toastify__close-button {
  opacity: 1 !important;
}

.center-center {
  bottom: 150px;
}

.edu {
  bottom: 300px;
}

/*---________________________Button ______________________---*/

.action-btn {
  position: relative;
  padding: 0;
  width: 100px;
  height: 30px;
  border: 2px solid #e1e1e1;
  outline: none;
  background-color: #f4f5f6;
  border-radius: 40px;
  transition: 0.13s ease-in-out;
  cursor: pointer;
  border: 2px solid;
}

.action-btn.btn.save-btn:hover {
  background-color: #f4f5f6 !important;
}

.action-btn:active {
  box-shadow: none;
  border-color: none !important;
}

.action-btn:active .button__content {
  box-shadow: none;
}

.action-btn:active .button__content .button__text,
.btn:active .button__content .button__icon {
  transform: translate3d(0px, 0px, 0px);
}

.action-btn-square {
  position: relative;
  padding: 0;
  width: 100px;
  height: 50px;
  border: 2px solid #888888;
  outline: none;
  background-color: #f4f5f6;
  border-radius: 10px;
  transition: 0.13s ease-in-out;
  cursor: pointer;
}

.action-btn-square:active {
  box-shadow: none;
}

.action-btn-square:active .button__content {
  box-shadow: none;
}

.action-btn-square:active .button__content .button__text,
.btn:active .button__content .button__icon {
  transform: translate3d(0px, 0px, 0px);
}

.action-btn-square .button__content {
  position: relative;
  display: grid;
  padding: 5px;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  box-shadow: inset 0px -8px 0px #dddddd, 0px -8px 0px #f4f5f6;
  border-radius: 10px;
  transition: 0.13s ease-in-out;
  z-index: 1;
}

.action-btn-square .button__icon {
  position: relative;
  display: flex;
  transform: translate3d(0px, -4px, 0px);
  grid-column: 4;
  align-self: center;
  justify-self: center;
  width: 20px;
  height: 20px;
  transition: 0.13s ease-in-out;
}

.action-btn-square .button__text {
  position: relative;
  transform: translate3d(0px, -4px, 0px);
  margin: 0;
  align-self: center;
  grid-column: 1/4;
  grid-row: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #888888;
  color: #5e5e5e;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  transition: 0.13s ease-in-out;
}

.action-btn .button__content {
  position: relative;
  display: grid;
  padding: 5px;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  background: white;
  box-shadow: inset 0px -5px 0px #dddddd, 0px -2px 0px #f4f5f6;
  border-radius: 40px;
  transition: 0.13s ease-in-out;
  z-index: 1;
}

.action-btn .button__icon {
  position: relative;
  align-items: center;
  display: flex;
  transform: translate3d(0px, -4px, 0px);
  grid-column: 4;
  align-self: center;
  justify-self: center;
  width: 20px;
  height: 20px;
  transition: 0.13s ease-in-out;
}

.action-btn .button__text {
  position: relative;
  transform: translate3d(0px, -4px, 0px);
  margin: 0;
  align-self: center;
  grid-column: 1/4;
  grid-row: 1;
  text-align: center;
  /* font-size: 15px;
  font-weight: bold; */

  font-size: 12px;
  font-weight: 400;
  background-color: #888888;
  color: #5e5e5e;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  transition: 0.13s ease-in-out;
}

.action-btn.saving-btn {
  /* position: relative;
  padding: 0;
  width: 100px;
  height: 50px; */
  border: 2px solid green;
  /* outline: none; */
  background-color: lightgreen !important;
  /* border-radius: 40px; */
  /* box-shadow: -6px -12px 10px #fff, -6px -10px 15px #fff, -21px 0px 6px #fff, 6px 6px 11px rgba(1, 164, 44, 0.2); */
  transition: 0.13s ease-in-out;
  cursor: pointer;
}

.action-btn.saving-btn:active {
  box-shadow: none;
}

.button__content:hover {
  background-color: #ececec;
}

.action-btn.saving-btn:active .button__content {
  box-shadow: none;
}

.action-btn.saving-btn:active .button__content .button__text,
.btn:active .button__content .button__icon {
  transform: translate3d(0px, 0px, 0px);
}

.action-btn.saving-btn .button__content {
  position: relative;
  display: grid;
  padding: 5px;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  background: rgb(162, 238, 162);
  box-shadow: inset 0px -5px 0px #92be7e, 0px -8px 0px #fff;
  border-radius: 40px;
  transition: 0.13s ease-in-out;
  z-index: 1;
}

.action-btn.saving-btn .button__icon {
  position: relative;
  display: flex;
  transform: translate3d(0px, -4px, 0px);
  grid-column: 4;
  align-self: center;
  justify-self: center;
  width: 20px;
  height: 20px;
  transition: 0.13s ease-in-out;
}

.action-btn.saving-btn .button__text {
  position: relative;
  transform: translate3d(0px, -4px, 0px);
  margin: 0;
  align-self: center;
  grid-column: 1/4;
  grid-row: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: green !important;
  color: rgb(17, 52, 17);
  text-shadow: 2px 2px 3px rgb(34, 73, 34);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  transition: 0.13s ease-in-out;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn,
.btn:hover {
  border: 2px solid #e1e1e1;
}

/* -----------------------------------------------------------------SmallSideBar---------------------------------- */

.sidebar {
  position: fixed;
  top: 0;
  right: -200px;
  width: 200px;
  height: 100vh;
  background-color: #f8f9fa;
  transition: right 0.3s ease;
}

.sidebar.open {
  right: 0;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: -30px;
}

.content {
  margin-left: 200px;
  padding: 20px;
}

/*--------------------- scrollbar -----------------*/
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bdbdbd;
  border-radius: 6px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #47c176;
  border-radius: 200px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #efefef;
}

.countdown div {
  left: 2%;
}

/*---------------------------------------swicth--------------------------*/
.toggle-collab {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  background-color: rgb(229, 68, 46);
  border-radius: 30px;
  border: 2px solid rgb(173, 173, 173);
}

.toggle-collab.checked::after {
  left: 70px;
}

.toggle-collab::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(173, 173, 173);
  border: 2px solid white;

  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

.checkbox {
  display: none;
}

.toggle-collab.checked {
  background-color: rgb(63, 231, 21);
}

.toggle-collab p {
  font-weight: bold;
}

/*************************************Input*******************/

@import url("https://rsms.me/inter/inter.css");

:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: rgb(133, 138, 133);
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: 0.5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
  padding: 0 calc(var(--size-bezel) * 3);
}

.input {
  position: relative;
}

.input__label {
  background: transparent;
  color: #555555;
  position: absolute;
  margin: 0 !important;
  left: 7px;
  top: -12px;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
  background: pink;
  white-space: nowrap;
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
  /* font-weight: 300; */
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
}

.input__field {
  text-align: center;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 2px solid rgb(179, 179, 179);
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}

.input__field:not(:-moz-placeholder-shown)+.input__label {
  /* transform: translate(0.25rem, -65%) scale(0.8); */
  color: var(--color-accent);
}

.input__field:not(:-ms-input-placeholder)+.input__label {
  /* transform: translate(0.25rem, -65%) scale(0.8); */
  color: var(--color-accent);
}

.input__field:focus+.input__label,
.input__field:not(:placeholder-shown)+.input__label {
  /* transform: translate(0.25rem, -65%) scale(0.8); */
  color: var(--color-accent);
}

.input .input__field:disabled {
  opacity: 0.5;
}

.divider {
  margin: 20px 20px 30px;
  height: 1px;
  background-color: #c4c2c2;
}

.form-check-input:checked {
  background-color: green !important;
}

/* --------header------------- */
.nav-circle {
  transition: all 0.3s ease-in;
  z-index: 2;
  /* background: #212f3c; */
  margin-bottom: 10px;
  border: 1px solid #dee2e663 !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.nav-circle:hover {
  border: 1px solid white;
  /* background: #ffffff47; */
  cursor: pointer;
  transform: scale(1.2);
}

.nav-circle svg:hover {
  stroke: #eb1c1c;

}

.navitem-li {
  transform: none !important;
  padding: 5px 10px !important;
}

.navitem {
  position: relative;
  transform: none !important;
  padding: 0px 10px !important;
}

.navitem,
.navitem>* {
  transition: all 0.3s ease-in;
}

.navitem:hover,
.navitem>*:hover {
  color: #5ae28e !important;
  /* text-decoration: underline; */
}

.navitem:hover {
  border-bottom: 3px solid #5ae28e;
}

.navitem-li:hover {
  background: initial !important;
}

/* Border X get width  */
/* .navitem:before,
.navitem:after {
  position: absolute;
  opacity: 0;
  width: 0%;
  height: 2px;
  content: "";
  background: linear-gradient(0.25turn, rgb(235 235 235), rgb(36, 113, 163));
  transition: all 0.3s;
}

.navitem:before {
  left: 0px;
  top: 0px;
}

.navitem:after {
  right: 0px;
  bottom: 0px;
}

.navitem:hover:before,
.navitem:hover:after,
#tutor-tab-header-list-active1::before,
#tutor-tab-header-list-active1::after {
  opacity: 1;
  width: 100%;
} */

.video-nav-icon:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
  /* Enlarge the icon on hover */
}

.blink_me {
  color: red;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  20% {
    opacity: 0;
  }
}

/*  */

.rbc-allday-cell {
  display: none;
}

.disabled-date.rbc-day-slot.rbc-time-column {
  pointer-events: auto !important;
}

.rbc-time-slot.enable-slot {
  background-color: white !important;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today .enable-slot {
  background-color: #eaf6ff !important;
}

.rbc-event-label {
  display: none !important;
}

.Toastify__toast {
  background: black !important;
  color: #fff !important;
}

.rbc-day-slot.rbc-time-column .place-holder-end-slot {
  border: 1px solid rgb(228, 204, 28)  !important;
  background-color: rgb(228, 204, 28) ;
  border-top: 0 !important;
}

.rbc-day-slot.rbc-time-column .rbc-timeslot-group .rbc-time-slot.place-holder-start-slot {
  border: 1px solid rgb(228, 204, 28) !important;
  background-color: rgb(228, 204, 28) ;

  border-bottom: 0 !important;
}

.rbc-day-slot.rbc-time-column .rbc-timeslot-group .rbc-time-slot.place-holder-start-slot::after {
  content: "Marked Slot for Booking";
  display: block;
  color: red;
  height: 100%;
  width: 100%;
  margin-top:10px  ; 
  font-size: 9px;
}

button.Toastify__close-button.Toastify__close-button--light {
  color: white;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #00ff001a;
}

.default-avatar {
  width: 150px;
  height: 150px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.language-selector {
  width: 18rem;
  font-size: 16px;
}

.custom-tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  position: absolute;
  background-color: rgb(33 47 60);
  color: #e1e1e1;
  border: 1px solid rgb(232 232 232 / 11%);
  padding: 5px;
  border-radius: 4px;
  z-index: 999;
}

.custom-tooltip.top {
  bottom: 20px;
  /* left: 4rem; */
}

.custom-tooltip.bottomleft {
  top: 20px;
  right: -27px;
}

.custom-tooltip.left {
  height: auto;
  top: -83px;
  left: -3rem;
}

.custom-tooltip.bottomleft::after {
  content: "";
  position: absolute;
  bottom: 100%;
  top: -4px;
  left: 90%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(245, 245, 245) transparent;

  background: rgb(33 47 60);
  border-right: 1px solid rgb(193, 193, 193);
  border-bottom: 1px solid rgb(193, 193, 193);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.custom-tooltip.bottomright {
  top: 20px;
}

.custom-tooltip.bottomright::after {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 10px;
  height: 10px;
  left: 8%;
  top: -4.7px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(232 232 232 / 11%) transparent;
  background: rgb(33 47 60);
  border-right: 1px solid rgb(193, 193, 193);
  border-bottom: 1px solid rgb(193, 193, 193);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.custom-tooltip.top::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 5%;
  border-width: 10px;
  border-style: solid;
  border-color: rgb(33 47 60) transparent transparent transparent;
  background: rgb(33 47 60);
  border-right: 1px solid rgb(193, 193, 193);
  border-bottom: 1px solid rgb(193, 193, 193);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* video recorder */
.record-button__Button-sc-1n5amwk-0.jbBlLf {
  margin: 0 !important;
}

input[type="checkbox"] {
  accent-color: green;
}

.form-check-input border border-dark:checked {
  background-color: green;
  border-color: green;
}




.hoveringListItem:hover {
  background-color: #dadada;
  cursor: pointer;
}

/* 
div > span {
  display: inline;
}

span[style*="display: inline;"] {
  white-space: nowrap;
}

span[style*="color: blue"] {
  display: inline;
  cursor: pointer;
  margin-left: 0;
  white-space: nowrap;
} */