
.agreement {
    display: block;
    position: relative;
    padding: 45px 0;
}

.agreement-item {
    display: flex;
    margin: 0 0 5px 0;
    width: 300px;
    white-space: nowrap;
}

.agreement-checkbox {
    float: left;
    width: 20px;
    margin-right: 10px;
}


/* ---------intro--------------- */
.openable-img {
    transition: all 0.3s ease-in;
}

.openable-img:hover {
    cursor: pointer;
    transform: scale(1.03);
    filter: brightness(0.7);
}



