.save_loader {
    position: relative;
    width: 85px;
    height: 50px;
    background-repeat: no-repeat;
    background-image: linear-gradient(#000 50px, transparent 0),
        linear-gradient(#000 50px, transparent 0),
        linear-gradient(#000 50px, transparent 0),
        linear-gradient(#000 50px, transparent 0),
        linear-gradient(#000 50px, transparent 0),
        linear-gradient(#000 50px, transparent 0);
    background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
    animation: rikSpikeRoll 0.65s linear infinite alternate;
}

@keyframes rikSpikeRoll {
    0% {
        background-size: 10px 3px;
    }

    16% {
        background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
    }

    33% {
        background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
    }

    50% {
        background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px
    }

    66% {
        background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px
    }

    83% {
        background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px
    }

    100% {
        background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px
    }
}

.tutor-popin {
    padding: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
    bottom: -100px;
    transition: .8s;
    z-index: 2;
}

.scroller-right {
    height: 40px;
    width: 40px;
    /* position: absolute; */
    right: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0, .1);
    z-index: 2;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
}

.input-cnt {
    display: flex;
}

.scroller-left {
    height: 40px;
    width: 40px;
    /* position: absolute; */
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0, .1);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 2;
    cursor: pointer;
}

.tutor-tab-header {
    height: 50px;
    padding: 0;
    margin: 0;
    display: flex;
    z-index: 998;
    align-items: center;
    position: relative;
    background: #212F3D;
    scroll-behavior: smooth;
}

ul.header::-webkit-scrollbar {
    display: block;
}

ul.header {
    list-style-type: none;
    margin: 0;
    height: 100%;
    width: calc(100% - 150px);
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 8px 0 8px;
    background: #212F3D;
    scroll-behavior: smooth;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

ul.header li {
    padding: 15px 20px 15px 20px;
    color: #fff;
    display: flex;
    transform: skew(-40deg);
    font-size: large;
    transition: all .2s ease-in;
    white-space: nowrap;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    float: left;
    cursor: pointer;
}

ul.header li a {
    display: block;
    font-size: 14px;
    transform: skew(40deg);
    transition: all .2s ease-in;
    background: transparent;
}

#tutor-tab-header-list-active1 {
    /* text-decoration: underline; */
    border-bottom: 3px solid #5ae28e;

}

#tutor-tab-header-list-active1>* {
    color: #5ae28e !important;
}

#active {
    background-color: #ffffff;
}

/* ul:not(.email-temp-preview *) {
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
} */

ul.header {
    height: 100%;
    overflow-x: auto;
}

ul li:not(.email-temp-preview *) {
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    float: left;
    cursor: pointer;
}

ul.nav.nav-tabs li {
    padding: 0 !important;
}

.tutor-tab-intro {
    height: 80px;
    width: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.tutor-tab-intro-notice {
    height: calc(100vh - 350px);
    width: 100%;
}

/* 
.note-one,
.note-two,
.note-three {
    height: 100%;
    width: 30%;
    margin: 0 1.6% 0 1.6%;
    padding: 10px;
    float: left;
} */

.tutor-tab-intro-prompt {
    height: 200px;
    width: 100%;
    margin: 20px 0 0 0;
}

table:not(.email-temp-preview *) {
    box-shadow: 2px 1px 15px 4px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 100%;
    text-align: center;
    position: relative;
    font-size: 13px;
    color: #6a6a6a;
    /* margin: 10px; */
}

td:not(.email-temp-preview *) {
    /* border: 1px solid gray; */
    border: none;
    /* border-radius: 10px; */
    text-align: left;
    width: 20px;
    padding: 0;
    text-align: center;
    height: 40px;
}

tr:nth-child(odd):not(.email-temp-preview *) {
    background-color: rgb(240, 240, 240);
}

th:not(.email-temp-preview *) {
    border: none;
    text-align: left;
    width: 20px;
    padding: 0;
    text-align: center;
    height: 40px;
    top: 0;
    color: #fff;
    position: sticky;
    background: #2471A3
}

tr:nth-child(even):not(.email-temp-preview *) {
    background-color: hsl(0, 0%, 100%);
}

table thead tr:first-child th:first-child:not(.email-temp-preview *) {
    border-top-left-radius: 12px;
}

table thead tr:first-child th:last-child:not(.email-temp-preview *) {
    border-top-right-radius: 12px;
}

/* table tbody tr:first-child td:first-child {
    border-top-left-radius: 12px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 12px;
} */

table tbody tr:last-child td:first-child:not(.email-temp-preview *) {
    border-bottom-left-radius: 12px;
}

table tbody tr:last-child td:last-child:not(.email-temp-preview *) {
    border-bottom-right-radius: 12px;
}

.tutor-tab-setup {
    height: calc(100vh - 60px);
    width: 100%;
}

form {
    height: 100%;
    width: 100%;
}


button {
    outline: none;
    border: none;
    margin: 10px;
    padding: 5px;
}

textarea {
    outline: none;
    background-color: hsl(0, 0%, 98%);
    border: 1px solid gray;
    border-radius: 2.5px;
    resize: none;
}

.tutor-setup-top-field {
    display: flex;
}

.tutor-setup-bottom-field {
    height: 40%;
    width: 100%;
    margin: 0;
    padding: 20px 0 0 0;

    background-color: #ffffff;
    float: left;
}

.tutor-setup-bottom-field div {
    width: 50%;
}

.tutor-tab-about-data {
    height: 100%;
    width: 50%;
    padding: 5px;
    float: right;
}

.tutor-tab-photo-frame {
    height: 250px;
    width: 250px;
    border: 1px dashed #000000;
}

.profile-photo-cnt {
    height: 50%;
    width: 50%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.profile-details-cnt {
    font-size: small;
    font-weight: 500;
}

select {
    height: 35px;
    border-radius: 2.5px;
    width: 300px;
    outline: none;
}

select#yr1,
#yr2,
#yr3,
#state1,
#state2,
#state3 {
    width: 100px;
}

#state1,
#state2,
#state3,
#university1,
#university2,
#degree,
#certificate {
    margin-top: 30px;
}

option {
    height: 50px;
    width: 300px;
    outline: none;
}

.tutor-tab-video-frame {
    height: 250px;
    width: auto;
    border: 1px dashed rgb(0, 0, 0);
}

.profile-video-cnt {
    height: 50%;
    width: 50%;
    float: right;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}



.tutor-tab-education-info {
    height: calc(100% - 40px);
    width: 60%;
    float: left;
    text-align: center;
    font-size: small;
    font-weight: 500;

}


.tutor-tab-education-experience {
    height: calc(100% - 40px);
    width: 40%;
    padding: 0 50px 0 50px;
    float: right;

}

.tutor-tab-education-work-experience-logo {
    height: 170px;
    width: 50%;
    margin: auto;
    text-align: center;
    display: block;
    margin-top: 10px;
    position: relative;
    border: 1px solid #000;
}


.highlight {
    font-family: 'Roboto', sans-serif !important;
    height: fit-content;
    width: 100%;
    margin: auto;
    text-align: justify;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #d4e9efad;
    padding: 10px;
    font-size: 14px;
}

.rate-table {
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
    padding: 10px;
}

.tutor-tab-accounting {
    height: calc(100vh - 120px);
    width: 100%;
    padding: 10px;
}

.tutor-tab-acct-start-day {
    position: relative;
    height: 100%;
    width: 25%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 40px;
    flex-direction: column;
    text-align: center;
    border: 1px solid #efefef;
}

.tutor-tab-acct-pay-option {
    height: 100%;
    width: 37.5%;
    overflow-y: auto;
    border: 1px solid #efefef;
}

.tutor-bank-info {
    pointer-events: none;
    opacity: .4;
}

#tutor-bank-info {
    pointer-events: all;
    opacity: 1;
}

.tutor-tab-acct-time-range {
    height: 100%;
    text-align: center;
    width: 37.5%;
    overflow-y: auto;
    border: 1px solid #efefef;
}

.tutor-tab-subjects {
    height: calc(100% - 200px);
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.tutor-tab-subjects-info {
    height: fit-content;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tutor-tab-subject-data-collection-table {
    height: fit-content;
    width: 90%;
    margin: auto;

}

#active-subject {
    border-bottom: 1px solid #000;
}

.tutor-tab-subject-data-tabs {
    height: 40px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background: #212F3D;
    margin: 0 0 20px 0;
    overflow-x: auto;
    position: relative;
}


#table_options_menu {
    background: #2471A3;
    color: #F7F9F9;
}

.tutor-tab-subject-data-tabs ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    background: #212F3D;
    scroll-behavior: smooth;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tutor-tab-subject-data-tabs ul::-webkit-scrollbar {
    display: block;
}


.tutor-tab-subject-data-tabs ul li {
    padding: 15px 20px 15px 20px;
    color: #fff;
    display: flex;
    transform: skew(-40deg);
    font-size: large;
    transition: all .2s ease-in;
    white-space: nowrap;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    float: left;
    cursor: pointer;
}

.tutor-tab-subject-data-tabs ul li a {
    display: block;
    font-size: 14px;
    transform: skew(40deg);
    transition: all .2s ease-in;
    background: transparent;
}

.tutor-tab-subject-search-bar {
    height: 50px;
    width: 100%;
}

.search-bar {
    float: right;
}

.form-my-students {
    height: 100%;
    width: 100%;
}

.form-term-of-use {
    padding: 5px;
}

.form-tutor-profile {
    position: relative;
    height: calc(100vh - 120px);
    width: 100%;
    overflow: auto;
}

.form-tutor-profile-head {
    height: 50px;
    width: 100%;
    padding: 5px;
    background-color: #979797;
}

.form-tutor-profile-body {
    height: calc(70% - 50px);
    width: 100%;
    background-color: #ffffff;
    display: flex;
}

.tutor-intel1,
.tutor-intel2,
.tutor-intel3 {
    height: 100%;
    width: 33.3%;
    font-size: medium;
    padding: 10px 0 10px 0;
    display: block;
    text-align: center;

}

.education-intel {
    width: 100%;
    height: calc(100% - 200px);
}

.form-tutor-profile-btm {
    height: 40%;
    width: 100%;
    margin: 100px 0 0 0;
    display: block;

}

.form-scheduling-cnt-month,
.form-scheduling-cnt-day {
    margin-top: 20px;
    width: 100%;
    display: none;
}

#form-scheduling-cnt {
    display: flex;
}

.form-scheduling-cnt-left {
    height: 100%;
    width: 15rem;
    float: left;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
}

.form-scheduling-hours {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: auto;
    overflow-y: scroll;
    border: 1px solid #80808029;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 10px;
}

.form-scheduling-cnt-right {
    width: calc(100% - 300px);
    float: right;
}

.form-scheduling-cnt-right-header {
    height: 60px;
    width: 100%;
    float: right;
    background-color: #2471A3;
}

#on {
    background-color: #979797;
    color: #ffffff;
    border: 1px solid #fff;
    font-weight: bold;
    border-radius: 5px;
}

.tutor-teaching-subject {
    height: 100px;
    width: 350px;
    padding: 5px;
    overflow: auto;
    border-radius: 10px;
    background-color: #efefef;
}

.tutor-teaching-subject div {
    padding: 8px;
    float: left;
    font-size: small;
    margin: 6px 4px 6px 4px;
    background-color: #2471A3;
    color: #fff;
    border-radius: 5px;
}


.header ul {
    list-style-type: none;
    margin: 0;
    float: right;
    height: 100%;
    width: calc(100% - 150px);
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 8px 0 8px;
    background: #212F3D;
    scroll-behavior: smooth;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

#tutor-acct-tab-menu-list-active {
    background: #2471A3;
    color: #F7F9F9;
}

/* 
::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
    background: rgb(117, 117, 117);
    border-radius: 200px;

}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 200px;
}

.grades-sec::-webkit-scrollbar {
    height: 8px;
}

.grades-sec::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.grades-sec::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    width: 5px;
} */